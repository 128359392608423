.header{
    background: #fff;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 35px;
    filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.086));
    z-index: 2;
}

.branchList {
    border: solid 1px #dedede;
    border-radius: 6px;
    max-height: 224px;
    overflow-y: scroll;
}

.branchListItem {
    padding: 10px;
    cursor: pointer;
    transition: .2s ease;
    height: 37px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        background: rgb(238, 238, 238);
    }
}