.halfTransparentButton {
    all:unset;
    display: flex;
    align-items: center;
    gap: 15px;
    color: #676767;
    cursor: pointer;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;

    img {
        display: block;
    }
    svg {
        display: block;
        margin: 0;
    }
}