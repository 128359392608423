.formEdit {
    input {
        margin-top: 5px;
    }
}

.generatePassswordButton {
    svg {
        margin: 0;
    }
}