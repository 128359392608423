.slot {
    background: #FFFBEE;
    border: 1px solid #ECECEC;
    border-radius: 6px;
    min-height: 80px;
    padding: 10px;
    display: grid;
    position: absolute;
    cursor: auto;
    transition: 0.2s ease;
    width: 100%;

    &:hover {
        z-index: 3;
        transform: scale(1.1);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        min-height: 80px;
    }
}

.off {
    transition: 0.2s ease;
    filter: brightness(0.8);

    &:hover {
        filter: brightness(1);
    }
}

.custom {
    background: #ffd587;
}

.edit_slot {
    border: 1px solid red;
}

.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}

.icon_time {
    margin-right: 8px;

    svg {
        display: block;
    }
}

.time {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    width: 100%;
    display: block;
    user-select: none;
}

.edit {
    all: unset;
    background: #ECECEC;
    border-radius: 50%;
    cursor: pointer;
    width: 36px;
    min-width: 36px;
    height: 36px;
    min-height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s ease;

    svg {
        all: unset;
        display: block;

        path {
            transition: 0.2s ease;
        }
    }

    &:hover {
        filter: unset;
        background: #FFD52F;

        svg {
            path {
                fill: #fff !important;
            }
        }
    }
}

.info {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #676767;
    user-select: none;
}