.container{
    height: 100%;
    position: relative;
    z-index: 1;
    padding-top: 60px;
    padding-left: 30px;
    display: grid;
    grid-template-columns: 1fr;
    //grid-auto-rows: min-content;
    //grid-template-rows: repeat(auto-fill, minmax(100px, 1fr));
}
.row{
    position: relative;
    min-height: 160px;
    height: 0%;
}