.item {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 1;
    color: #000000;
    padding: 8px 16px;
    background: #ECECEC;
    border: 1px solid #ECECEC;
    border-radius: 6px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 1fr 25px;
    min-height: 38px;
}

.logo{
    width: 22px;
    min-width: 22px;
    max-width: 22px;
    height: 22px;
    min-height: 22px;
    max-height: 22px;
    display: block;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    display: block;
    margin-right: 15px;
}

.text {
    display: flex;
    align-items: center;
    user-select: none;
}

.img {
    cursor: pointer;
    margin-left: auto;
    display: block;

    path {
        transition: 0.2s ease;
    }

    &:hover {
        path {
            fill: #1e1e1e !important;
        }

    }
}

.readonly {
    margin-top: 2px;
    margin-left: auto;
    display: block;
}


