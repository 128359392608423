.container {
    display: block;
    width: 100px;
}

.logo {
    width: 100px;
    max-width: 100px;
    min-width: 100px;
    height: 100px;
    min-height: 100px;
    max-height: 100px;
    display: block;
    object-fit: cover;
    border-radius: 50%;
}

.not_image {
    display: block;
    width: 100px;
    max-width: 100px;
    min-width: 100px;
    height: 100px;
    min-height: 100px;
    max-height: 100px;
    border-radius: 50%;
    border: 1px solid #ECECEC;
    box-shadow: inset 0px 0px 15px rgb(0 0 0 / 10%);
}

.error {
    border-color: #ff5c5c;
    box-shadow: inset 0px 0px 15px #ff5c5c91;
}

.row {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 10px;
}

.edit {
    all: unset;
    display: block;
    cursor: pointer;

    svg {
        all: unset;
        display: block;
    }
}

.delete {
    all: unset;
    display: block;
    cursor: pointer;

    svg {
        all: unset;
        display: block;
    }
}