.container{
    position: relative;
    height: calc(100% - 144px);
    &:after{
        content: "";
        display: block;
        background: #ECECEC;
        position: absolute;
        top: 0;
        left: -32px;
        height: 100%;
        width: 32px;
        z-index: 8;
    }
    &:before{
        content: "";
        display: block;
        background: #ECECEC;
        position: absolute;
        top: 0;
        right: -32px;
        height: 100%;
        width: 32px;
        z-index: 8;
    }
}
.tabel{
    background: #ffffff;
    //width: calc(((100vw - 32px) - 32px) - 261px);
    height: 100%;
    overflow: scroll;
    position: relative;
}