.schedule {
    height: 100%;
    padding-left: 30px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    //z-index: 0;
}

.schedule_header {
    height: 40px;
    display: grid;
    grid-template-columns: repeat(24, 180px);
    position: sticky;
    top: 0px;
    background: #ffffff;
    z-index: 6;
    transition: all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1) 0s;
    //border-bottom: 1px solid #eee;
}

.schedule_time {
    display: flex;
    align-items: center;
    background: #FFF;
    border-bottom: 1px solid #eee;
    span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #676767;
        transform: translateX(-17px);
    }
}

.schedule_content {
    height: calc(100% - 40px);
    display: grid;
    grid-template-columns: repeat(24, 180px);
   // margin-top: 40px;
}

.schedule_column {
    border-left: 1px solid #B5B5B5;
    position: relative;

    &:before {
        content: "";
        display: block;
        width: 1px;
        height: 100%;
        opacity: 0.35;
        position: absolute;
        left: 50%;
        top: 0;
        bottom: 0;
        right: 50%;
        border-left: 1px dashed #B5B5B5;;
    }
}