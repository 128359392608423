.item {
    padding: 24px 18px;
    display: flex;
    align-items: center;
    position: relative;
}

.text {
    position: relative;
    cursor: default;
}

.full_name {
    display: block;
    position: absolute;
    background: #FFF;
    //white-space: nowrap;
    //width: 100%;
    padding: 3px 5px;
    border: 1px solid #c4c4c4;
    border-radius: 3px;
    z-index: 1;
    top: 100%;
    left: 100%;
    opacity: 0;
    transition: 0.2s ease;
    transform: scale(0);
}
.full_name__active{
    opacity: 1;
    transform: scale(1);
}