.calendar {
    width: 375px;
    margin: 0 auto;
}

.calendarHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.monthButton {
    all: unset;
    align-items: center;
    background: #d2dfff;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    height: 25px;
    justify-content: center;
    width: 25px;
}

.left {

}

.right {

}

.label {
    position: relative;
    overflow: hidden;
}

.labelText {
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    min-width: 200px;
    background: #fff;
    text-align: right;
}

.input {
    color: #fff !important;
    border: none !important;
    margin: 0 !important;
    user-select: none !important;
    width: 160px;
}

.calendarContent {

}

.daysOfWeek {
    text-transform: uppercase;
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.dayOfWeek {
    cursor: default !important;
    border-color: transparent !important;
    color: #000;
}

.cell {
    width: 45px;
    height: 45px;
    border-radius: 3px;
    border: solid 1px #ececec;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    font: normal normal 600 16px/19px Montserrat;
    transition: .2s ease;
}

.month {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
}

.day {


    &:not(.noactive):not(.checked):hover {
        background: rgba(255,213,47,.5);
        border-color: #ffd52f;
    }
}

.noactive {
    background: #ececec !important;
    border-color: #ececec !important;
    color: #6a6a6a !important;
    cursor: default !important;
}

.checked {
    background: #ffd52f;
    border-color: #ffd52f;
}