.header {
    display: inline-grid;
    grid-template-columns: repeat(7, minmax(220px, 1fr));
    grid-column-gap: 10px;
    border-bottom: 1px solid #B5B5B5;
}

.day {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 20px;
    margin-bottom: 10px;
    position: relative;
    min-width: 220px;
}

.custom{
    &:after{
        content: "";
        display: block;
        position: absolute;
        top: calc(100% + 9px);
        width: 100%;
        height: 3px;
        background: #ff8d00;
    }
}

.col_left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.col_right {
    margin-left: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 7px;
}