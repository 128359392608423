.modal{
    position: absolute;
    background: rgba(#FFF, 0.5);
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    z-index: -1;
    transition: 0.2s ease-in-out;
}

.modal__active{
    position: absolute;
    background: rgba(#FFF, 0.5);
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    z-index: 10;
    transition: 0.2s ease-in-out;
}

.popup{
    max-width: 700px;
    width: 100%;
    background: #FFF;
    filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.086));
    transform: scale(0);
    opacity: 0;
    transition: 0.7s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.popup__active{
    max-width: 700px;
    width: 100%;
    background: #FFF;
    filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.086));
    transform: scale(1);
    opacity: 1;
    transition: 0.7s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.header{
    background: #ffd52f;
    padding: 15px;

}
.header_error{
    background: #FF9A84;
}
.title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 1;
    text-align: center;
    color: #000000;
    margin: 0px;
}

.content{
    padding: 20px 90px;
}