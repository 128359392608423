.container{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
}
.guide{
    display: grid;
    grid-template-columns: 24px 1fr;
    grid-gap: 8px;
    align-items: center;
    img{
        width: 24px;
        min-width: 24px;
        max-width: 24px;
        height: 24px;
        min-height: 24px;
        max-height: 24px;
        border-radius: 50%;
        overflow: hidden;
        object-fit: cover;
    }
    span{
        display: block;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        color: #FFFFFF;
    }
}