.page {
    background: #ECECEC;
    display: grid;
    grid-template-columns: auto 1fr;
    min-height: 100vh;
    max-height: 100vh;
    height: 100vh;
    transition: 0.2s ease;
}

.container {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 80px calc(100vh - 80px);
}

.content {
    padding: 16px 32px;
    height: auto;
    overflow-y: scroll;
    display: grid;
    //min-height: 100%;
}