.container {
    display: inline-flex;
    flex-direction: row;
    align-items: stretch;
    padding: 0px;
    margin: 0px;
    list-style-type: none;
}

.button {
    all: unset;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 32px;
    background: #ECECEC;
    border-radius: 3px;
    cursor: pointer;

    svg {
        all: unset;
        display: block;
    }
}
.button_container{
    display: block;
}

.pages {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
}

.number_page {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    min-width: 40px;
    height: auto;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #B5B5B5;
    cursor: pointer;
    a{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }
}
.current_page{
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    height: auto;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    cursor: pointer;
}