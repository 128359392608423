.authPage {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.authBlock{
    background: #fff;
    max-width: 1276px;
    width: 100%;
}

.authBlockHeader {
    height: 216px;
    background: #FFD52F;
}
.authBlockHeaderBG {
    height: 100%;
    background: url('../../../assets/images/Rectangle 313.png') center no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}

.authBlockContent {
    height: 617px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    form {
        display: flex;
        flex-direction: column;
        width: 512px;

        div {
            display: flex;
            flex-direction: column;
        }
    }
}

