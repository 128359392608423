.table{
    min-height: 66px;
    display: grid;
    grid-template-columns: 1fr;
    background: #ffffff;
    margin-bottom: 50px;
}

.content{
    min-height: 66px;
}

.header{
    background: #FFFBEE;
    min-height: 66px;
    height: auto;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    display: grid;
}