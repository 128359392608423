.field{
    padding: 0px 16px;
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 6px;
    margin-bottom: 15px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    display: flex;
    align-items: center;
}
.error{
    border-color: #ff5c5c;
}
.text{
    margin-left: 15px;
}
.icon{
    margin-left: auto;
}