.container {
    cursor: pointer;
}

.header {
    text-decoration: none;
    text-transform: unset;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    padding: 18px 16px;
    transition: 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    min-height: 76px;

    &:hover {
        background: rgba(#FFD52F, 0.15);
    }

    svg {
        display: block;
        transition: 0.2s ease;
        transform: rotate(-90deg);
        margin-left: auto;
    }
}

.header__active {
    background: rgba(#FFD52F, 0.15);

    &:hover {
        background: #FFD52F;
    }

    svg {
        transform: rotate(0deg)
    }
}

.icon {
    max-width: 30px;
    max-height: 30px;
    display: block;
    object-fit: cover;
}

.list {
    display: grid;
    grid-template-columns: 1fr;
    height: auto;
    overflow: hidden;
    transition: 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
    max-height: 0px;
    opacity: 0;
}

.list__active {
    max-height: 500px;
    opacity: 1;
}

.link {
    text-decoration: none;
    text-transform: unset;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    padding: 18px 50px;
    transition: 0.2s ease-in-out;
    white-space: nowrap;

    &:hover {
        background: rgba(#FFD52F, 0.15);
    }
}

.link__active {
    text-decoration: none;
    text-transform: unset;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    background: #FFD52F;
    padding: 18px 50px;
    white-space: nowrap;
}