.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #FFF;
    border-radius: 6px;
    overflow: hidden;
}

.date[type="date"] {
    padding: 8px 16px !important;
    margin: 0px !important;
    border: 0px;
}

.prefix {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 15px;
    line-height: 1;
    color: #000000;
    text-align: center;
    padding: 0px 10px;
}

.button{
    all: unset;
    cursor: pointer;
    padding: 9px 15px;
    svg{
        all: unset;
        display: block;
    }
}