.container{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    line-height: 1;
    border-radius: 6px;
    margin-bottom: 15px;
}