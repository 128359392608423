.cell{
    padding: 10px 18px;
    display: flex;
    align-items: center;
    //justify-content: center;
}

.logo{
    width: 50px;
    max-width: 50px;
    min-width: 50px;
    height: 50px;
    min-height: 50px;
    max-height: 50px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
}
.small{
    width: 25px;
    max-width: 25px;
    min-width: 25px;
    height: 25px;
    min-height: 25px;
    max-height: 25px;
}
.text{
    margin-left: 10px;
}