.sidebar {
    background: #FFF;
    position: absolute;
    min-width: 404px;
    height: 100%;
    top: 80px;
    right: 0px;
    bottom: 0px;
    z-index: 9;
    border-left: 1px solid #ECECEC;
    transform: translateX(100%);
    transition: 0.4s ease-in-out;
}
.sidebar__left{
    transform: translateX(-100%);
    right: unset;
    left: 0px;
}

.show {
    transform: unset;
}

.header {
    background: #ffd52f;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;

    h2 {
        margin: 0px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #000000;
    }
}

.move {
    all: unset;
    display: block;
    cursor: pointer;

    svg {
        display: block;
        margin-right: 0px;
    }
}
.move__left{
    svg {
        transform: rotate(180deg);
    }
}

.close {
    all: unset;
    display: block;
    cursor: pointer;

    svg {
        display: block;
        margin-right: 0px;
    }
}

.content {
    padding: 15px 15px !important;
    display: flex;
    flex-direction: column;
    height: calc(100% - 139px);
    overflow-y: scroll;
}

.date {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;

    svg {
        display: inline-block;
    }

    p {
        display: inline-block;
        margin: 0px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 1;
        color: #000000;
    }
}

