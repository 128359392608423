.buttonOutlined {
    all:unset;
    display: flex;
    align-items: center;
    gap: 15px;
    color: #000;
    cursor: pointer;
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
    padding: 10px 16px;
    border-radius: 32px;
    border: solid 1px #FFD52F;

    &:hover {
        filter: unset;
    }

    img {
        display: block;
    }
    svg {
        display: block;
        margin: 0;
    }
}