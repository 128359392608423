.label{
    display: block;
    position: relative;
    margin-bottom: 15px;
}
.input[type="text"]{
    margin-bottom: 0px;
    padding-right: 50px;
}
.textarea{
    margin-bottom: 0px;
    padding-right: 50px;
}
.logo{
    position: absolute;
    top: 7px;
    right: 16px;
    position: absolute;
    z-index: 1;
    width: 23px ;
    min-width: 23px;
    max-width: 23px;
    height: 23px;
    min-height: 23px;
    max-height: 23px;
    object-fit: cover;
    border-radius: 50%;
    display: block;
    overflow: hidden;
}
