.logoBlock {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 15px;

    figure {
        width: 100px;
        height: 100px;
        margin: 0;
        border-radius: 50%;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.logoBlockBottom {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 100px;

    & > div {
        cursor: pointer;
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    svg {
        display: block;
    }
}

.checkbox {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.deleteLanguage {
    margin-left: 2px;
}

.formEdit {
    input {
        margin-top: 5px;
    }
}