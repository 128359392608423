.container {
    padding: 10px;
    background: #FFFBEE;
    border: 1px solid #ECECEC;
    border-radius: 6px;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
}

.img {
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    width: 25px;
    min-width: 25px;
    max-width: 25px;
    height: 25px;
    min-height: 25px;
    max-height: 25px;
    object-fit: cover;
    margin-right: 10px;
}

.edit {
    all: unset;
    display: block;
    cursor: pointer;

    svg {
        all: unset;
        display: block;
    }
}

.time{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #000000;

    svg {
        display: inline-block;
        margin-right: 5px;
    }
}