.button{
    all: unset;
    background: unset;
    border: unset;
    cursor: pointer;
    position: relative;
    z-index: 3;
}
.icon{
    display: block;
    margin: 0px;
    svg{
        all: unset;
        display: block;
    }
    //path{
    //    fill: #ff1f1f;
    //}
}

.description {
    display: block;
    position: absolute;
    background: #FFF;
    //white-space: nowrap;
    //width: 100%;
    padding: 3px 5px;
    border: 1px solid #c4c4c4;
    border-radius: 3px;
    z-index: 3;
    top: 100%;
    left: 100%;
    opacity: 0;
    transition: 0.2s ease;
    transform: scale(0);
}
.description__active{
    opacity: 1;
    transform: scale(1);
}