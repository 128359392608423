.page {
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
}
.pageHeaderTop {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 30px;
}
.pageHeaderTopLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.breadcrumbs {
    margin-bottom: 10px;
    
    a {
        text-decoration: none;
    }
}
.pageName {
    font-size: 30px;
    line-height: 37px;
    font-weight: 700;
    line-height: 56px;
}
.pageHeaderTopRight {
    display: flex;
    align-items: center;
    gap: 10px;

    button {
        margin: 0;
    }
}
.pageContent {
    height: 100%;
}