.blocked {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;

}

.day {
    position: relative;
}

.day_blocked {
    filter: grayscale(100%) contrast(0.8);
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #B5B5B5;
    padding: 20px 5px 5px;
}

.date {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #676767;
    text-transform: capitalize;
}

.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
.add {
    all: unset;
    display: block;
    cursor: pointer;

    svg {
        all: unset;
        display: block;
    }
}

.delete {
    all: unset;
    display: block;
    cursor: pointer;

    svg {
        all: unset;
        display: block;
    }
}

.main {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
    margin: 10px 3px 0px;
}