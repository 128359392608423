.header {
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: auto 1fr auto;
}

.button {
  all: unset;
  cursor: pointer;
  padding: 9px 15px;

  svg {
    all: unset;
    display: block;
  }
}

.week {
  width: 250px !important;
  margin-bottom: 0px !important;
}

.container_date {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 10px;
  margin-left: auto;
}

.container_title {
  margin: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.date {
  width: 250px !important;
  margin-bottom: 0px !important;
}