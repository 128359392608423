.cell{
    padding: 10px 18px;
    display: flex;
    align-items: center;
    position: relative;
}
.label{
    width: 4px;
    height: 100%;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
}