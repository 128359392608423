.item{
    display: block;
    position: absolute;
    top: calc(50% - 7px);
    left: -9px;
    font-weight: 500;
    font-size: 13px;
    line-height: 1;
    color: #000000;
    transform: rotate(-90deg);
}