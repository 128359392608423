.pass {
    height: 0;
    opacity: 0;
    transition: all .2s ease;
}

.svg {
    svg {
        margin: 0;
    }
}

.active {
    height: 55px;
    opacity: 1;
}

.deleteAccount {
    margin-left: 19px;
    cursor: pointer;
}

.formEdit {
    input {
        margin-top: 5px;
    }
}