.container{
    position: relative;
    display: flex;
    align-items: center;
    gap: 15px;
}

.headerBranches {
    display: flex;
    align-items: center;

    svg {
        display: block;
    }
}

.branchAdress{
    margin: 0 21px;
}

.branchList {
    position: absolute;
    left: 0;
    bottom: -20px;
    transform: translateY(100%);
    width: 100%;
    background: #fff;
    // max-height: 50px;
    overflow-y: scroll;
}