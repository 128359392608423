.header {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.button {
  all: unset;
  cursor: pointer;
  padding: 9px 15px;

  svg {
    all: unset;
    display: block;
  }
}

.week {
  width: 250px !important;
  margin-bottom: 0px !important;
}
