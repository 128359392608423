.sidebar {
    background: #FFF;
    position: absolute;
    top: 80px;
    bottom: 0;
    right: 0;
    width: 400px;
    z-index: 10;
    height: 100%;
    border-left: 1px solid #ECECEC;
    transform: translateX(100%);
    transition: 0.4s ease-in-out;
}

.show {
    box-shadow: 0px 0px 15px rgb(0 0 0 / 9%);
    transform: unset;
}

.scroll {
    height: calc(100vh - 80px);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
}

.header {
    padding: 20px 15px;
    display: grid;
    grid-template-columns: 1fr 25px;
    grid-gap: 8px;
    align-items: flex-start;

}

.name_excursion {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    display: block;
}

.close {
    all: unset;
    display: block;
    cursor: pointer;
    transition: 0.1s linear;

    &:hover {
        transform: scale(1.2);
    }

    svg {
        margin: 0px;
        display: block;
    }
}

.form {
    height: 100%;
    display: block;
    padding: 25px 20px;

    overflow-y: scroll;
}

.row {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 15px;
    padding-bottom: 15px;

    svg {
        display: block;
        grid-column: 1;
    }

    span {
        display: flex;
        align-items: center;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 1;
        color: #000000;
        grid-column: 2;

    }
}

.price {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
}

.price_count {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
}

.price_status {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: lowercase;
}

.bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;

    button {
        padding: 8px 32px;
        background: #ECECEC;
        min-width: unset;
        min-height: unset;
        margin: 0px;

        svg {
            display: block;
            margin: 0;
        }
    }
}

.counter {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #000000;
}