.link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    text-decoration: none;
    padding: 5px 10px;
    box-sizing: border-box;
}

.link__active {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    text-decoration: none;
    padding: 5px 10px;
    background: #ffd52f;
    box-sizing: border-box;
}