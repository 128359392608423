.cell{
    padding: 0px 0px 0px 18px;
    display: flex;
    align-items: center;
    position: relative;
}
.color{
    width: 100%;
    height: 100%;
    display: block;
}