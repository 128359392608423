.slots {
    display: inline-grid;
    grid-template-columns: repeat(7, minmax(210px, 1fr));
    grid-column-gap: 10px;
    margin-top: 10px;
    height: 1440px;
}

.day {
    position: relative;
    //display: grid;
    //grid-template-columns: 1fr;
    //grid-row-gap: 10px;
    //grid-auto-rows: min-content;
    height: 1440px;
    min-width: 220px;
}

.block_day {
    position: absolute;
    background: rgba(255, 255, 255, 0.7);
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 5;
}