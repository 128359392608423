.cart {
    display: inline-block;
    height: auto;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 30%);
    cursor: pointer;

    &:hover {
        z-index: 9;
    }
}

.title {
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
}

.type_excursion {
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    margin-bottom: 10px;
}

.dollar {
    position: absolute;
    bottom: 5px;
    right: 5px;
    display: block;
    filter: drop-shadow(0px 0px 2px rgba(0,0,0,0.3));
    svg {
        //  stroke-width: 30
    }

}