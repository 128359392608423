.page {
    height: 100%;

    &-header {

        &-top {
            margin-bottom: 34px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            &-left {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
        }

        &-bottom {

        }
    }

    &-content {
        background: #fff;
        height: 100%;
    }
}