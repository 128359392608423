.form {
    all: unset;
    box-sizing: border-box;
    background: #ffffff;
    height: 56px;
    width: 400px;
    display: grid;
    grid-template-columns: 1fr 55px;
    align-items: center;
    border-radius: 3px;
    overflow: hidden;
}

.input,
.input[type=text] {
    all: unset;
    box-sizing: border-box;
    height: 100%;
    padding: 10px;
}

.button {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: 0.2s ease;
    background: #FFD52F;
    filter: unset;

    svg {
        all: unset;
        display: block;

        path {
            fill: #000000;
            transition: 0.2s ease;
        }
    }

    &[disabled] {
        cursor: default;
        background: unset !important;
        filter: unset !important;

        svg {
            path {
                fill: #B5B5B5 !important;
            }
        }
    }

}