.customSelect {
    position: relative;
    z-index: 2;

    &.active {

        .arrow {
            transform: rotate(180deg);
        }


    }

    &:not(.active) {
        .customSelectContent {
            height: 0;
            opacity: 0;
        }
    }

}

.customSelectHeader {
    padding: 10px 16px;
    border: solid 1px #ECECEC;
    background: #fff;
    border-radius: 6px;
    cursor: pointer;
}

.customSelectHeaderInner {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 10px;
}

.arrow {
    position: absolute;
    top: 0;
    right: 0;
    transition: all .2s ease;
    background: #fff;
}

.contentAbsolute {
    position: absolute;
    width: 100%;
    z-index: 10;
}

.customSelectContent {
    background: #fff;
    border: solid 1px #ECECEC;
    border-radius: 6px;
    overflow: hidden;
    height: 122px;
    transition: all .2s ease;
}

.customSelectItem {
    padding: 10px 16px;
    cursor: pointer;
    transition: all .2s ease;
    user-select: none;
    display: flex;
    align-items: center;
    gap: 10px;
    &:hover {
        background: #f7f7f7;
    }
}

.scroll {
    overflow-y: scroll;
}

.item_img{
    width: 25px;
    height: 25px;
    min-height: 25px;
    min-width: 25px;
    max-width: 25px;
    max-height: 25px;
    display: inline-block;
    overflow: hidden;
    object-fit: cover;
    border-radius: 50%;
}

.item_checkbox {
    width: 20px;
    height: 20px;
    border: solid 1px #B5B5B5;
    border-radius: 3px;
}

.selected {
    background-image: url('../../../assets/images/checkbox.svg');
    border-color: #FFD52F;
}

.readonly {
    .customSelectHeader {
        background: #dedede;
        cursor: default;
    }

    .arrow {
        display: none;
    }
}