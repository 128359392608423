.cell{
    padding: 10px 18px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
}

.photo{
    width: 24px;
    max-width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
    max-height: 24px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    display: inline-block;
}