.navbar {
    background: #FFF;
    height: 100%;
    min-height: 100%;
    filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.086));
    z-index: 1;
    overflow: overlay;
    z-index: 10;
}

.logo {
    display: block;
    margin: 16px;
    width: calc(100% - 32px);
    max-width: 114px;
}

.nav {
    display: flex;
    flex-direction: column;

}

.link {
    text-decoration: none;
    text-transform: unset;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    padding: 18px 16px;
    transition: 0.2s ease-in-out;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    min-height: 76px;
    &:hover {
        background: rgba(#FFD52F, 0.15);

    }
}

.link__active {
    text-decoration: none;
    text-transform: unset;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    background: #FFD52F;
    padding: 18px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    min-height: 76px;
}