.input[type="checkbox"] {
    all: unset;
    height: 15px !important;
    width: 24px !important;
    min-width: 24px !important;
    z-index: 1;
    position: relative;
    display: block;
    transition: 0.1s ease-in-out;
    cursor: pointer;
    &:checked::before,
    &::before {
        all: unset;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background: #B5B5B5;
        border-radius: 35px;
        z-index: 2;
        transition: 0.1s ease-in-out;
    }

    &:checked::after,
    &::after {
        all: unset;
        content: "";
        display: block;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: #ffffff;
        position: absolute;
        z-index: 3;
        top: calc(50% - 5px);
        left: 2px;
        transition: 0.1s ease-in-out;
    }


    //&::after {
    //    //transform: translateX(10px);
    //}
    //
    &:checked::after {
        transform: translateX(8px)
    }
    &:checked::before {
        background: #FFD52F;
    }
    //
    &:disabled{
        cursor: unset;
    }
    &:disabled:checked::after {
        background: #c4c4c4;
    }

    &:disabled::before {
        background: #ebebeb;
    }

}